// MVOM colours
$blue: #3d618a;
$dark-blue: #1b1f48;
$light-blue: #a9c5d1;
$brown: #5d3220;
$taupe: #7a7878;
$light-taupe:#e9e6df;
$light-grey: #f4f4f4;
$grey: #999;
$dark-grey: #555;
$black: #000;
$white: #fff;
$link-color: #5688c1;
$footer-text-color: #8a8a89;

$headings-font-family: 'tekton-pro', sans-serif;
$font-family-base: 'proxima-nova', sans-serif;

$primary: $blue;

// set up the variables for colour options
$theme-colors: (
        "primary": $blue,
        "secondary": $brown,
);

$custom-colors: (
        "blue": $blue,
        "light-blue": $light-blue,
        "brown" : $brown,
        "taupe": $taupe,
        "light-taupe": $light-taupe,
        "light-grey": $light-grey,
        "grey" : $grey,
        "dark-grey" : $dark-grey,
        "black" : $black,
        "white" : $white,
);

$container-max-widths: (
        sm:1320px,
        md:1320px,
        lg:1320px,
        xl: 1320px,
        xxl: 1320px
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl:1600px,
        xxxxl:1920px,
        xxxxxl:2500px
);

$spacer: 1rem;
$grid-gutter-width: 3rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5
);

$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5
);

$position-values: (
        0: 0,
        50: 50%,
        100: 100%
);

$accordion-icon-color:$blue;
$accordion-button-active-color: $blue;

$border-radius-lg: .5rem;

$btn-hover-bg-shade-amount: 75%;

$nav-tabs-link-active-bg: $light-grey;

$progress-bg:#fff;

$box-shadow-sm: 0 .1rem .45rem rgba($black, .095);
$box-shadow:0 0 .75rem rgba($black, .2);

$table-hover-bg: #eef2e4;

// offcanvas (currently used for mobile nav slide-in) variables
$offcanvas-bg-color: $blue;
$offcanvas-color: $light-grey;

$theme-colors: map-merge($theme-colors, $custom-colors);
